body,
html {
    height: 100%;
    margin: 0;   
    overflow: hidden;
    font-weight: lighter;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Thasadith', sans-serif;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: linear-gradient(
        rgba(32, 35, 44, 0.6), 
        rgba(32, 35, 44, 0.6)),
        url(assets/background1.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity: 0.9;
    animation: transitionIn 1.00s;
}

/* Body animation....*/
.animated{
    animation: transitionIn 1.00s;
}

@keyframes transitionIn{
    from{
        opacity:0;
        transform: rotateX(-10deg);
    }

    to{
        opacity: 1;
        transform: rotateX(0);
    }
}

::-webkit-scrollbar{
    width: 0px;
}

/* Typer Writer Animation....... */
#typewriter
{
    display: inline-block;
    overflow: hidden;
    padding: 1px;
    margin: 1px;
    animation: typing 5s steps(30, end), blink .75s step-end infinite;
    white-space: nowrap;
    border-right: 4px solid orange;
    box-sizing: border-box;
}

@keyframes typing {
    from 
    { 
        width: 0% 
    }
    
    to 
    { 
        width: 30%;
    }
}

@keyframes blink {
    from, to 
    { 
        border-color: transparent 
    }
    
    50% 
    { 
        border-color: orange; 
    }
}

/* Content Details.........*/
.content {
    font-weight: lighter;
    text-align: left;
    padding: 6.2%;
    width:100vw;  
    height: 100vh;
    vertical-align: middle;
    display: table-cell;  
    padding-top: 0vh;
    color: whitesmoke;
    font-weight: 100;
    animation: transitionIn 2.00s;
}

.big{
    font-size:400%;
    padding: 1px;
    margin: 1px;
}

.medium{
    font-size:240%;
    padding: 1px;
    margin: 1px;
}

.small{
    font-size:100%;
    padding: 1px;
    margin: 1px;
}
#forgot,
#acsvc,
#loginlink{
    cursor: pointer;
}

/* Button Style........*/
input[type="button"]{
    text-align:center; 
    vertical-align:middle;
    margin:0.5%;
    margin-top: 23px;
    width: 110px;
    height: 40px;
    background: transparent;
    border: 1.5px solid #E65100;
    border-radius: 2px;
    color: #FFF;
    font-size: 96%;
    transition: 0.1s ease;
    cursor: pointer;
    letter-spacing: 0.1em;
    font-weight: 400;
}

/* Skill Submit........*/
input[type="submit"] {
    text-align:center; 
    vertical-align:middle;
    margin:0.5%;
    width: 110px;
    height: 40px;
    background: linear-gradient(to right, #B24592, #F15F79 );
    border: none;
    border-radius:50px; 
    color: #FFF;
    font-size: 70%;
    opacity: 0.7;
    transition: 0.1s ease;
    cursor: pointer;
    letter-spacing: 0.1em;
    font-weight: 400;
}

/* Button,Submit effect style......*/
input[type="button"]:hover,
input[type="submit"]:hover,
input[type="button"]:focus{
    outline: 0;
    opacity: 0.8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    transition: 0.1s ease;
    animation: transitionIn 1.00s;
}

input[type="button"]:active {
    opacity: 1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    transition: 0.1s ease;
}
input[type="submit"]:hover,
input[type="submit"]:focus {
    outline: 0;
    opacity: 0.8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    transition: 0.1s ease;
    
}

input[type="submit"]:active {
    opacity: 1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    transition: 0.1s ease;
}

/* Connect.....*/
img
{
    margin-left: 20px;
    outline: none; 
    border: none;
}
img:hover
{
   transform: scale(1.1,1.1);
   
  
}

/*Table........*/
#mytab {
    text-align:center;
    border-collapse: collapse;
    width: 50%;
    margin-top: 5px;
    color: white;
}
#mytab td, #mytab th {
    text-align:center;
    border: none;
    padding: 7px;
    color: white;
}


#mytab tr{
    background-color: rgba(32, 35, 44, 0.3);
    color: white;
}

#mytab th {
    text-align:center;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(32, 35, 44, 0.3);
    color: white;
}

p{
    font-weight: lighter;
}

/*Navbar........*/
.navbar{
    background-color: rgba(32, 35, 44, 0.5);
}

.navbar-toggler:focus, 
.navbar-toggler:active {
    outline: none;
    box-shadow: none;
}

/* For Mobile Screen........*/
@media only screen and (max-width: 768px) {
    .content {
        padding-top: 0vh;
        text-align: center;
        
        
    }
    #typewriter
    {
        display: inline-block;
        overflow: hidden;
        padding: 1px;
        margin: 1px;
        animation: typing 3s steps(10, end), blink .75s step-end infinite;
        white-space: nowrap; 
    }
    @keyframes typing {
        from 
        { 
            width: 0% 
        }
        
        to 
        { 
            width: 60%;
        }
    }
    
    @keyframes blink {
        from, to 
        { 
            border-color: transparent 
        }
        
        50% 
        { 
            border-color: orange; 
        }
    }

    .navbar{
        background-color: rgba(32, 35, 44, 1);
        opacity:1;
    }


    .big{
        text-align:center;
        font-size:270%;
    }

    .medium{
        text-align:center;
        font-size:180%;
    }
}

.navbar-brand{
    cursor: pointer;
}